import { useEffect, useContext, useCallback, useState } from "react";

import Header from "./Components/Headers";
import Context from "./Context";

import styles from "./App.module.scss";

export interface Config {
  email: string,
  phone: string,
  plaidApiUrl: string,
}

const App = () => {
  const [config, setConfig] = useState<Config>({
    email: '',
    phone: '',
    plaidApiUrl: ''
  });

  useEffect(() => {
    getConfig();
  }, [])

  const getConfig = async () => {
    const settings = await fetch('/settings/plaid-ui.json');
    setConfig(await settings.json());
  }

  const { dispatch } = useContext(Context);

  const getInfo = useCallback(async () => {
    if (config.plaidApiUrl === '') {
      return {};
    }
    const request = `${config.plaidApiUrl}/api/info`;
    const response = await fetch(request, { method: "POST" });
    if (!response.ok) {
      dispatch({ type: "SET_STATE", state: { backend: false } });
      return { paymentInitiation: false };
    }
    const data = await response.json();
    const paymentInitiation: boolean = data.products.includes(
      "payment_initiation"
    );
    dispatch({
      type: "SET_STATE",
      state: {
        products: data.products,
      },
    });
    return { paymentInitiation };
  }, [dispatch, config]);


  const generateToken = useCallback(
    async (paymentInitiation) => {
      const path = paymentInitiation
        ? `${config.plaidApiUrl}/api/create_link_token_for_payment`
        : `${config.plaidApiUrl}/api/create_link_token`;
      const urlParams = window.location.search;
      const params = new URLSearchParams(urlParams);
      const token = params.get('token');

      if (!token) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: { error_message: 'No token, please follow link from your email or contact us', error_code: '400', error_type: 'Token missing' },
          },
        });
        return;
      }

      if (config.plaidApiUrl === '') {
        return;
      }
      const response = await fetch(path, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, redirectUrl: window.location.protocol + '//' + window.location.host + window.location.pathname})
      });

      if (response.status === 201) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: { error_message: 'Journey for this merchant and bank is already complete.', error_code: '201', error_type: 'Journey completed' },
          },
        });
        return;
      }

      if (response.status === 400) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: null,
            linkTokenError: { error_message: 'Token in invitation is not valid.', error_code: '400', error_type: 'Token validation failed' },
          },
        });
        return;
      }

      if (!response.ok) {
        dispatch({ type: "SET_STATE", state: { linkToken: null } });
        return;
      }
      const data = await response.json();
      if (data) {
        if (data.error != null) {
          dispatch({
            type: "SET_STATE",
            state: {
              linkToken: null,
              linkTokenError: data.error,
            },
          });
          return;
        }
        dispatch({ type: "SET_STATE", state: { linkToken: data.link_token } });
      }
      localStorage.setItem("link_token", data.link_token); //to use later for Oauth
    },
    [dispatch, config.plaidApiUrl]
  );

  useEffect(() => {
    const init = async () => {
      const { paymentInitiation } = await getInfo(); // used to determine which path to take when generating token
      // do not generate a new token for OAuth redirect; instead
      // setLinkToken from localStorage
      if (window.location.href.includes("?oauth_state_id=")) {
        dispatch({
          type: "SET_STATE",
          state: {
            linkToken: localStorage.getItem("link_token"),
          },
        });
        return;
      }
      generateToken(paymentInitiation);
    };
    init();
  }, [dispatch, generateToken, getInfo]);

  return (
    <div className={styles.App}>
      <div className={styles.container}>
        <Header config={config} />
      </div>
    </div>
  );
};

export default App;
