import React, { useContext } from "react";
import Callout from "plaid-threads/Callout";
import Button from "plaid-threads/Button";
import InlineLink from "plaid-threads/InlineLink";

import Link from "../Link";
import Context from "../../Context";

import styles from "./index.module.scss";
import { Config } from "../../App";

interface HeaderProp {
  config: Config
}

const Header = ({ config }: HeaderProp) => {
  const {
    linkToken,
    linkSuccess,
    isItemAccess,
    backend,
    linkTokenError,
  } = useContext(Context);

  return (
    <div className={styles.grid}>
      <img className={styles.headerlogo} alt="Liberis Logo" src='https://recruiting.cdn.greenhouse.io/external_greenhouse_job_boards/logos/000/011/187/original/Secondary_Logo_Colour.png?1617202160'></img>
      <h3 className={styles.title}>Connect Liberis to your Banking Data</h3>

      {!linkSuccess ? (
        <>
          <h4 className={styles.subtitle}>
            In order to give you the best offer we need an understanding of your business income.
          </h4>
          <p className={styles.introPar}>
            Clicking the link below begins the connection from your bank
            account to our systems. Through this we are able make a better assessment of your financial circumstances, increasing your chances of funding and potentially increasing your borrow limit.
          </p>
          {/* message if backend is not running and there is no link token */}
          {!backend ? (
            <Callout warning>
              Unable to fetch link_token: please make sure your backend server
              is running and that your .env file has been configured with your
              <code>PLAID_CLIENT_ID</code> and <code>PLAID_SECRET</code>.
            </Callout>
          ) : /* message if backend is running and there is no link token */
            linkToken == null && backend ? (
              <Callout warning className='error'>
                <div>
                  Error Code: <code>{linkTokenError.error_code}</code>
                </div>
                <div>
                  Error Type: <code>{linkTokenError.error_type}</code>{" "}
                </div>
                <div>Error Message: {linkTokenError.error_message}</div>
                <div>
                  <a className={styles.links} href={"tel:" + config.phone}>{config.phone}</a> or email us at <a className={styles.links} href={'mailto' + config.email}>{config.email}</a>
                </div>
              </Callout>
            ) : linkToken === "" ? (
              <div className={styles.linkButton}>
                <Button large disabled>
                  Loading...
                </Button>
              </div>
            ) : (
              <div className={styles.linkButton}>
                <Link conf={config} />
              </div>
            )}
        </>
      ) : (
        <>
          {isItemAccess ? (
            <div>
              <h4 className={styles.subtitle}>
                Thank you! By linking your account we can now validate your income and calculate the best price possible for your business. A sales agent will be in contact with you soon regarding your quote.
              </h4>
              <h4>If you need any support please call <a className={styles.links} href='tel:18442001963'>18442001963</a> or email us at <a className={styles.links} href='mailto:sales@liberisfinance.com'>sales@liberisfinance.com</a></h4>
            </div>
          ) : (
            <h4 className={styles.subtitle}>
              <Callout warning>
                Unable to create connection to your bank account. please call <a className={styles.links} href={"tel:" + process.env.REACT_APP_LIBERS_PHONE_NUMBER}>{process.env.REACT_APP_LIBERS_PHONE_NUMBER}</a> or email us at <a className={styles.links} href={'mailto' + process.env.REACT_APP_LIBERS_EMAIL}>{process.env.REACT_APP_LIBERS_EMAIL}</a>
              </Callout>
            </h4>
          )}
        </>
      )
      }
    </div >
  );
};

Header.displayName = "Header";

export default Header;
